import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const MessageFieldContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 2px;
`;

export const Accordion = styled.form<{ show: boolean }>`
  display: grid;
  grid-template-rows: ${({ show }) => (show ? 1 : 0)}fr;
  transition: grid-template-rows 2s ease;
`;

export const AccordionContent = styled.div``;

export const HelpText = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

export const BottomField = styled.div`
  width: 100%;
  margin-top: 100px;
  padding: 0;
`;

export const Label = styled.label`
  display: block;
  ${({ theme: { font } }) => font.t3};
`;

export const PasswordInputContainer = styled.div`
  position: relative;
`;

export const HideShowButtonContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  display: flex;
`;

export const HideShowButton = styled.div`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LoginLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.div<{ $isSuccess: boolean }>`
  ${({ theme: { font } }) => font.t1};
  display: flex;
  justify-content: ${({ $isSuccess }) => ($isSuccess ? 'center' : 'left')};
`;

export const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
