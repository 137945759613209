import React from 'react';
import styled from 'styled-components';
import { Button } from '@seddi/ui-kit';

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  background-color: #ffffff !important;
  border: 1px solid #8c8c8c !important;
  color: #5e5e5e !important;
  transition: background-color 0.2s ease !important;
  height: 40px !important;
  padding: 0 12px !important;
  border-radius: 4px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  &:hover {
    background-color: #f5f5f5 !important;
  }
`;

interface GoogleLoginButtonProps {
  onClick: () => void;
}

export const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onClick }) => {
  return (
    <StyledButton
      onClick={onClick}
      variant='outlined'
      width='full'
      data-dd-action-name='GoogleSignIn'
      text={
        <ButtonContent>
          <GoogleIcon src='/img/google.svg' alt='Google logo' />
          Sign in with Google
        </ButtonContent>
      }
    />
  );
};
