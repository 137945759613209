import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CommunicationPanel, ThemeProvider } from '@seddi/ui-kit';

import { APP_LOOKUP } from 'src/constants';
import { SeddiLoginState } from 'src/store/store';
import { AppName } from 'src/types';
import { SeddiTheme } from 'src/theme/SeddiTheme';

const Wrapper: React.FC<{ children: React.ReactNode; brandName?: AppName }> = ({
  children,
  brandName,
}) => {
  if (brandName === 'seddi') {
    return <ThemeProvider theme={SeddiTheme}>{children}</ThemeProvider>;
  }
  return <>{children}</>;
};

export const FormContainer: React.FC<{
  title?: string;
  text?: string | React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  brandName?: AppName;
  mode?: 'centered' | 'sidePanel';
  panelPosition?: 'left' | 'right';
  displayBackground?: boolean;
}> = ({
  children,
  title,
  text,
  brandName,
  panelPosition,
  loading = false,
  mode = 'sidePanel',
  displayBackground = true,
}) => {
  const { name } = useSelector((state: SeddiLoginState) => state.app);

  const logoImageSrc = useMemo(() => {
    const currentName = brandName || name;
    const defaultLogo = `${window.location.origin}/img/${APP_LOOKUP[currentName].brandLogo}`;
    return mode !== 'centered' && currentName === 'seddi'
      ? `${window.location.origin}/img/${APP_LOOKUP[currentName].brandLogo2}`
      : defaultLogo;
  }, [name, brandName, mode]);
  const backgroundImageSrc = useMemo(
    () => `${window.location.origin}/img/${APP_LOOKUP[brandName || name].backgroundImage}`,
    [name, brandName],
  );
  const backgroundGradient = useMemo(
    () => APP_LOOKUP[brandName || name].gradient,
    [name, brandName],
  );

  const productName = `Seddi ${name[0].toUpperCase() + name.slice(1)}`;

  return (
    <Wrapper brandName={brandName}>
      <CommunicationPanel
        backgroundGradient={backgroundGradient}
        backgroundImage={displayBackground ? backgroundImageSrc : (undefined as any)}
        brandLogo={logoImageSrc}
        brandName={productName}
        panelPosition={panelPosition || 'left'}
        mode={mode}
        showFooter={true}
        title={title}
        text={text}
        loading={loading}
      >
        {children}
      </CommunicationPanel>
    </Wrapper>
  );
};
