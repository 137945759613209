import styled from 'styled-components';

export const CreateAccountSubmitButtonContainer = styled.div`
  margin-top: auto;
  margin-bottom: 1rem;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
`;
