import React from 'react';
import { ThemeProvider } from '@seddi/ui-kit';
import { I18n } from 'react-redux-i18n';
import { SeddiTheme } from 'src/theme/SeddiTheme';
import { ThemeApp } from '../../theme/theme';
import * as S from './ProductPage.styles';
import { Button } from '@seddi/ui-kit';
import {
  TEXTURA_URI,
  SEDDI_URI,
  AUTHOR_URI,
  DECORATOR_URI,
  FITROOM_URI,
} from '../../constants/constants';
import { FormContainer } from '../../components/formContainer/FormContainer';

const loginTo = (name: string) => {
  switch (name) {
    case 'author':
      window.location.href = AUTHOR_URI;
      break;
    case 'decorator':
      window.location.href = DECORATOR_URI;
      break;
    case 'fitroom':
      window.location.href = FITROOM_URI;
      break;
    case 'textura':
      window.location.href = TEXTURA_URI;
      break;
    case 'seddi':
    default:
      window.location.href = SEDDI_URI;
      break;
  }
};

const ProductPage: React.FC<{}> = () => {
  const authorTheme = structuredClone(SeddiTheme);
  authorTheme.color.s1 = ThemeApp.color.sa1;
  authorTheme.color.s2 = ThemeApp.color.sa2;

  const decoratorTheme = structuredClone(SeddiTheme);
  decoratorTheme.color.s1 = ThemeApp.color.sd1;
  decoratorTheme.color.s2 = ThemeApp.color.sd2;

  const texturaTheme = structuredClone(SeddiTheme);
  texturaTheme.color.s1 = ThemeApp.color.st1;
  texturaTheme.color.s2 = ThemeApp.color.st2;

  const fitroomTheme = structuredClone(SeddiTheme);
  fitroomTheme.color.s1 = ThemeApp.color.sf1;
  fitroomTheme.color.s2 = ThemeApp.color.sf2;

  return (
    <ThemeProvider theme={SeddiTheme}>
      <FormContainer brandName={'seddi'} panelPosition='left' mode={'sidePanel'} loading={false}>
        <S.MessageFieldContainer>
          <ThemeProvider theme={decoratorTheme}>
            <Button
              text={I18n.t('auth.loginDecorator')}
              data-dd-action-name='SignIn'
              type='button'
              width='full'
              onClick={() => loginTo('decorator')}
            />
          </ThemeProvider>
          <ThemeProvider theme={authorTheme}>
            <Button
              text={I18n.t('auth.loginAuthor')}
              data-dd-action-name='SignIn'
              type='button'
              width='full'
              onClick={() => loginTo('author')}
            />
          </ThemeProvider>
          <ThemeProvider theme={texturaTheme}>
            <Button
              text={I18n.t('auth.loginTextura')}
              data-dd-action-name='SignIn'
              type='button'
              width='full'
              onClick={() => loginTo('textura')}
            />
          </ThemeProvider>
        </S.MessageFieldContainer>
      </FormContainer>
    </ThemeProvider>
  );
};

export default ProductPage;
